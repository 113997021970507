// import { useAuth } from "oidc-react";

// import { Loader } from "@shared/CircleLoader";

export const HomeComponent = () => {

  return (
    <div>
      I am here professional
    </div>
  );
};
